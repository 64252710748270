:root {
  --bs-dark-newgreen: #005b89;
  --bs-light-newgreen: #cce8f4;
  --bs-dark-newblue: #b73439;
  --relative: relative;
}

.servies#gallery {
  background-color: #fff;
}

.react-photo-album--photo {
  object-fit: cover;

  border-radius: .8em;
}

.ullist.contactdetails p {
  margin: 0 0 0em;
  font-size: 1.5em;
} 
.testimonials b {
  color: burlywood;
  width: 100%;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.6em;
}
.testimonials .carousel-indicators,
.testimonials .carousel-control-next, 
.testimonials .carousel-control-prev {
  display: none;
}

.testimonials .banner .carousel-caption {
  right: 0;
  bottom: initial;
  width: 100%;
  padding: 0;
  top: 0;
  left: 0;
  text-align: left;
  position: relative;
}
.testimonials .banner .carousel-caption h6 {
  margin: 0rem 0 1.5rem;
  padding: 1em 2em;
  color: #fff;
  background: #014161;
  /* border-radius: 0.8em; */
}
.testimonials .banner.carousel.slide {
  height: 100%;
}
.heading1 {
  background: #f1f1f1 !important;
  font-weight: 600;
  font-size: 18px;
}
.lifepic .img-fluid {
  height: 250px;
  object-fit: cover;
}
.ullist li {
  list-style: none;
  color: #005B89;
}
.ullist li svg {
  margin-right: 6px;
  color: #005B89;
}
summary {
  display: block;
}
.lifepic {
 display: inline-flex;
 width: 100%;
 gap: 1em;   
}
.contactcontainer.videoplayer {
  background-color: #000;
}
.downloadfile {
  text-decoration: auto;
  font-size: 18px;
  color: #fff;
  background: #4CAF50;
  padding: .4em 1em .6em;
}

.downloadfile svg {
  padding-left: 5px;
  width: 25px;
  height: 20px;
  position: relative;
  top: -1px;
}
.heading h6.download {
  font-size: 1.4em;
  font-weight: bold;
  color: #014161;
}
a.subcribe {
  text-decoration: none;
}
.heading .aboutcontact a.subcribe h5.H5 {
  color: #fff;
  font-size: 1.2em;
  margin: 1em 0 0;
  text-align: center;
}
.morevideos,
a.subcribe h5.H5 b {
  color: #fff;
  font-size: 14px;
  text-transform: none;
  font-weight: 100;
}
.morevideos {
  width: 100%;
  display: inline-block;
  text-align: center;
  text-decoration: underline;
}
a.subcribe svg{
  width: 45px;
  height: 45px;
  fill: #dc3500;
}
.contactcontainer.videoplayer .embed-responsive-item  {
  width: 100%;
  height: 345px;
}
.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4 img {
  object-fit: cover;
  border-width: 0;
}
.bi.bi-twitter rect {
  fill:#005b89;
  fill-opacity:1;
  stroke:none;
}
.bi.bi-twitter path {
  stroke:none;
  fill-rule:evenodd;    
  fill: rgb(178 221 238);
  fill-opacity:1;
}
/* .navbar-nav .footer-social-icon  {
  margin-left: 1.6em;
  margin-top: 2px;
} */
.navbar-nav .footer-social-icon svg.bi,
.navbar-nav .footer-social-icon svg.bi.bi-twitter path,
.contact svg.bi,
.contact svg.bi.bi-twitter path {
  fill: #fff;
}

.navbar-nav svg.bi.bi-twitter rect,
.contact .bi.bi-twitter rect {
  fill: #014161;
}

/* .contact-container svg.bi.bi-twitter {
  width: 40px;
  height: 40px;
} */

/* .wahtapp-cont {
  background-color: #3ec250;
  border-radius: 4px;
  color: #fff;
  font-size: 13px;
  letter-spacing: -.0em;
  margin-right: 7px;
  padding: 6px 12px 8px;
} */

._h1tag h1 {
  font-weight: 700
}
/* .whatapp.fixed-bottom {
  left: initial;
  margin: 1em;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
} */
.contact-container .banner .button-50 {
  display: none;
}
.Toastify__toast-container--top-right {
  position: relative;
  width: 100%;
  margin: 24px 0 0;
  top: 0;
  right: 0
}

._h1tag {
  position: absolute;
  z-index: 1;
  color: aliceblue;
}

.article_6 .contactcontainer h6,
.article_6 .H6 {
  color: var(--bs-dark-newblue);
}
.innerbanner img {
  object-fit: cover;
  width: 100vw;
  height: 395px;
  margin-top: 85px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 94%);
  filter: grayscale(1) brightness(0.5);
}
.article_6 .H6 {
  margin: 1em 0;
}
.article_6 .contactcontainer h6 {
  margin-bottom: 1.6em;
}
.article_6 p {
  color: var(--bs-dark-newgreen);
}

.cont-12 {
  background: #f8f6f4;
}
.article_6 h6,
.H6 {
  font-weight: bold;
  color: #005B89;
}

.aboutus p,
.banner .carousel-caption h6 {
  color: var(--bs-dark-newgreen);
}
.navbar-expand-lg .navbar-nav .dropdown-item {
  display: inline-flex;
  flex-flow: column;
  border: 1px solid var(--bs-dark-newgreen);
  margin: 0 1em 2.5em;
  padding: 1rem;
  color: var(--bs-dark-newgreen);
  box-shadow: 7px 8px 0px 0px #014161 !important;
  height: 162px;
  white-space: normal;
  
}
.navbar-expand-lg .navbar-nav .dropdown-item:hover {
  transform: translateY(-6px);
  background-color: var(--bs-light-newgreen);
  /* box-shadow: 7px 8px 0px 0px #014161 !important; */
}
.navbar-expand-lg .navbar-nav .dropdown-item h2 {
  font-size: 1.6em;
  color: #b73439;
  font-weight: bold;
}

.navbar-expand-lg .navbar-nav .dropdown-item p {
  font-size: 14px;
}
.heading h6 {
  color: var(--bs-dark-newblue);
  font-weight: 400;
  padding: 0;
  margin: 1em 0;
 }
.H5,
.banner .carousel-caption h1,
.banner .carousel-caption h5 {
  font-weight: 400;
  color: var(--bs-dark-newgreen);
  text-transform: uppercase;
}

.banner .carousel-caption h1,
.banner .carousel-caption h5 {
  color: var(--bs-dark-newblue);
  font-weight: 600;
}
.alternative-cta-overline-wrapper {
  grid-column-gap: 0.75rem;
  /* align-items: center; */
  display: flex;

}
.alternative-cta-pellet {
  width: 0.75rem;
  height: 1rem;
  background-color: var(--bs-dark-newblue);
  flex: none;
  top: 8px;
  position: relative;
}

.affix .img-fluid {
  width: 195px;
}
.affix .bg-body-tertiary.navbar {
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
  box-shadow: 0 0 30px rgb(115 128 157 / 10%);
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.card {
  cursor: pointer;
}
.card-body h4 {
    /* color: var(--bs-dark-newgreen); */
    font-weight: 700;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0em;
  background-color: #005b89;
  background-position: center;
  background-size: 34px 34px;
  border-radius:100%;
  filter: none !important;
}
.about#ourteam,
.contact,
.servies  {
  clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 98%);
}
.footer-section,
.about#ourteam,
.contact,
.servies  {
  margin-top: -2.6rem;
}

.contact {
  background-color: #005B89;
}
.contact.heading .serviesh6 {
  color: #fff;
}
.contact .nav-link,
.contact.heading .H5,.contact.heading h6 {
  color: #ffd249;
}

.contact.heading .H5::after {
   background-color: #ffff
}

/* .contact {
  padding: 3em 0;
} */
.contact .nav-link {
  font-size: 1.3em;
  font-style: italic;
  color: #fff;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: left;
}

.navbar-nav .footer-social-icon svg.bi,
.contact svg.bi,
.contact .nav-link svg {
  background: #014161;
  width: 40px;
  height: 40px;
  padding: 7pt;
  border-radius: 6px;
  margin-right: 6px;
}

.contact .footer-social-icon {
  margin-top: 1em;
}
/* //Team */
.mg-top {
  margin-top: 2em;
}


.user-card-designer {
  border-radius: 0em;
  margin: 1.5em 6px 0;
  display: inline-grid;
  width: calc(33.33% - 12px);
  padding: 0;
  background-color: #fff;
  position: relative;

}
.user-card-intro {
  padding: 12px 1rem 1rem;
}
.user-card-designer img {
  width: 100%;
  padding: 0rem;
  height: 214px;
  object-fit: cover;
  border-radius: 0rem;
  filter: grayscale(100%);
  clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
}

.card-info {
  padding: 0 1rem;
    /* background: var(--bs-dark-newgreen); */
}

.card-link {
  position: absolute;
  bottom: 46%;
  right: 2%;
}
/* .about,.aboutus {
  background: aliceblue;
} */

.about#ourteam {
  /* padding: 3em 0 3em; */
  background-color: #e5eef3;
}
.user-card-intro p {
  color: var(--bs-dark-newgreen);
  font-style: italic;
  margin: 0;
  font-size: 14px;
}
.user-card-intro h3 {
  font-size: 1.2em;
  color: var(--bs-dark-newblue);
  /* font-weight: 300; */
}
/* //Footer CSS */
.socialmedia {
  margin-right: 6px;
  background: #014161;
  color: #fff;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  border-radius: 100%;
}
/* .link-primary {
   color: var(--bs-dark-newgreen) !important;
} */
.border-primary {
  border-color: rgba(var( --bs-dark-newgreen),var(--bs-border-opacity))!important;
  border-radius: 0;
}
ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #b2ddee;
  position: relative;
  clip-path: polygon(0 0, 100% 2%, 100% 100%, 0% 100%);
}

.footer-cta {
  border-bottom: 1px solid var(--bs-dark-newgreen);
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: var(--bs-dark-newgreen);
  /* font-size: 20px; */
  font-weight: 700;
  margin-bottom: 8px;
}

.cta-text span {
  color: var(--bs-dark-newgreen);
  font-size: 16px;
  /* font-weight: 500; */
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 16px;
  color: var(--bs-dark-newgreen);
  line-height: 28px;
  /* font-weight: 500; */
}

.footer-social-icon span {
  color: var(--bs-dark-newgreen);
  display: block;
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Poppins', sans-serif; */
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: var(--bs-dark-newgreen);
  font-size: 16px;
  margin-right: 2px;
}

.footer-widget .footer-social-icon a {
  margin-right: 12px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: var(--bs-dark-newblue);
}

.twitter-bg {
  background: var(--bs-dark-newblue);
}

.google-bg {
  background: var(--bs-dark-newblue);
}

.footer-widget-heading h3 {
  color: var(--bs-dark-newgreen);
  /* font-size: 20px; */
  font-weight: 700;
  margin-bottom: 40px;
  position: relative;
}
.heading .H5 {
  margin: 0 0 2rem;
  padding: 0;
}
.heading .H5::after,
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 5px;
  width: 50px;
  background: var(--bs-dark-newblue);
}
.heading .H5::after {
  /* left: calc(var(--bs-gutter-x) * .5); */
    /* bottom: -9px; */
    height: 10px;
    width: 135px;
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 100%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: var(--bs-dark-newblue)
}

.footer-widget ul li a {
  color: var(--bs-dark-newgreen);
  text-decoration: none;
  text-transform: capitalize;
  /* font-weight: 500; */
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: var(--bs-dark-newblue);
  padding: 13px 20px;
  border: 1px solid var(--bs-dark-newblue);
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: var(--bs-dark-newgreen);
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #fff;
}

.copyright-text p a {
  color: #fff;
  text-decoration: none;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ffc107;
}

.footer-menu li a {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
}

.bg-body-tertiary.navbar {
  background-color: var(--bs-white) !important;
}

.navbar-collapse .navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-dark-newgreen);
  font-weight: bold;
}

.nav-link {
  color: var(--bs-dark-newgreen);
}

h5.H5 {
  font-weight: bold;
  position: var(--relative);
}
/* .heading .H5 {
  color: var(--bs-dark-newblue);
} */

.navbar-expand-lg .navbar-nav._darkblue .nav-link {
  /* color: var(--bs-dark-newblue); */
  font-weight: 600;
  /* font-style: italic; */
  /* border-radius: 26px;
   background: #bfffce;
   padding: 3px 1.5em 7px; */
}

.button-50 {
  appearance: button;
  background-color: var(--bs-dark-newblue);
  background-image: none;
  border: 1px solid var(--bs-dark-newblue);
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, var(--bs-dark-newblue) 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  /* font-family: ITCAvantGardeStd-Bk,Arial,sans-serif; */
  font-size: 1.12em;
  font-weight: 600;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 16px 20px;
  text-transform: uppercase;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.universityDropdown .button-50 {
  padding: 12px 0;
  width: 130px;
  font-size: 1em;
}

.button-50:focus {
  text-decoration: none;
}

.button-50:hover {
  text-decoration: none;
}

.button-50:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.button-50:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, var(--bs-dark-newblue) 2px 2px 0 1px;
  transform: translate(2px, 2px);
}
/* 
.aboutus {
  padding: 2em 0 0;
} */


.card-body h4 {
  font-size: 2em;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 156px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.card-body a.link-primary,
.card-body p.text-secondary {
  /* font-size: 14px; */
  color: var(--bs-dark-newgreen)!important;
  margin: 0px;
  font-weight: 400;
}

.card-body a.link-primary {
  font-weight: 600 !important;
  position: absolute;
  left: 0;
  padding: 4px 12px;
  background-color: #b73439;
  color: #fff !important;
  bottom: 0;
  clip-path: polygon(0 0, 96% 0, 100% 100%, 0% 100%);
}
.heading .serviesh6{
  font-weight: 400;
  color: var(--bs-dark-newgreen);
  font-style: italic;
  font-size: 1.2em;
}

.servies .serviesh6 {
  margin: 2em 0;
}
.servies {
  background: #f8f6f4;
}

.card.border-0:hover {
  transform: translateY(-6px);
  /* background-color: #e1f5ff; */
  box-shadow: 7px 8px 0px 0px #014161 !important;
}

.card-body {
  padding: 0;
  height: 395px;
  box-shadow: 0 2px 4px 2px rgba(0,0,0,.1);
}

.card-body img {
  object-fit: cover;
  width: 100%;
  filter: brightness(0.5);
  height: 200px;
     clip-path: polygon(0 0, 100% 0, 100% 95%, 0 88%);
}
.contactcontainer {
  padding: 3rem;
  background: var(--bs-light-newgreen);
  border-radius: 0;
}
.contact-container .contact {
  z-index: 2;
  position: relative;
}
@media only screen and (min-width:1200px) and (max-width:1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1000px;
}
}


@media only screen and (min-width:992px) {
 
 .margin8 {
    margin: 6em 0 0;
}
.navbar-nav .footer-social-icon {
    margin-left: 1.6em;
    margin-top: 2px;
}

  .contact-container {
    margin-top: 86px;
}
._h1tag p {
  font-size: 20px;
  font-style: italic;
}

  ._h1tag {
    top: 10em;
    left: 10%;
    width: 50%;
  }
  .innerbanner .button-50 {
    padding: 16px 20px;
}
  .navbar-expand-lg .navbar-nav .dropdown-item {
    width: calc(100% / 2 - 2em);
  }
  .contactcontainer {
    box-shadow: 7px 8px 0px 0px #014161 !important;
  }
  .sticky_header{
    position: sticky;
    top: 120px;
    height: 100%;
    bottom: 0;
  }
  .aboutus .container {
    box-shadow: 6px 10px 0px 0px var(--bs-dark-newgreen);
    background-color: var(--bs-light-newgreen);
    padding: 2rem 2rem;
  }
  
  .aboutus .container {
    margin-top: -5em;
    z-index: 1009;
    position: relative;
  }
   .heading h6 {
    font-size: 2.4em;
   }
  
  .navbar-collapse .navbar-nav .nav-link {
    font-size: 14px !important;
    /* font-weight: 300; */
  }

  /* .navbar-expand-lg .navbar-nav._darkblue .nav-link {
    font-size: 1em !important;
  } */

  .button-50 {
    padding: 15px;
  }

  /* .carousel-control-next-icon::before, .carousel-control-prev-icon::before {
    content: "";
    background-color: #005b89;
    width: 3em;
    height: 3em;
    display: inline-block;
    z-index: -1;
    position: absolute
    } */

  img.d-block.w-100 {
    object-fit: cover;
    width: 45vw !important;
    height: 560px;
    object-position: bottom;
    float: inline-end;
    /* border-radius: 300px 0 0 300px; */
    margin-top: -10px;
    transform: scale(1);
    margin-right: -2em;
    clip-path: polygon(23% 0, 100% 0%, 100% 99%, 0% 100%);
  }

  .bannercontainer {
    /* background: #ffffef; */
    top: 1px;
    padding: 0px;
    position: relative;
}

  .banner.carousel.slide {
    height: 512px;
    /* background: #fff; */
    position: relative;
  }

  .banner .carousel-caption {
    right: 6%;
    bottom: initial;
    width: calc(100% - 40vw);
    top: 2em;
    left: 6%;
    text-align: left;
    position: relative;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 6%;
  }

  .m-right-3 {
    margin-right: 1em;
  }

  .H5,
  .banner .carousel-caption h1,
  .banner .carousel-caption h5 {
    font-size: 2.2em;
  }

  .article_6 .contactcontainer h5.H5,
  .article_6 .H6 {
  font-size: 1.6em;
  }

  .banner .carousel-caption h6 {
    font-size: 1.6em;
    margin: 1rem 0 1.5rem;
    padding-right: 2em;
    font-weight: 100;
  }
  .article_6 .contactcontainer {
    height: 670px;
    border-radius: 1rem;
    padding: 1.8em;
    top: -12em;
    position: relative;
}
  /* .article_6 p, */
  .article_6 h6,
  .aboutus p {
    font-size: 1.2em;
  }



  .heading .aboutcontact .H5  {
    font-size: 2.2rem;
    margin: 0 0 0.6rem;
}

.heading .aboutcontact .H5::after {
  display: none;
}

.article_6 .contactcontainer h6,
.heading .aboutcontact h6 {
  font-size: 1.2em;
  font-weight: 500;
  margin: 0 0 1.2em;
}


.aboutus {
  padding: 2em 0 0em;
}

.article_6 {
  padding: 4em 0 6em;
}

.contact,

.about,
.about#ourteam,
.servies .container {
  padding: 7.5em 0 8.5em;
}
.servies .mb-4 {
  margin-bottom: 2.5em!important;
}
.containtfixed {
  margin-top: 90px;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border-radius: 0;
  border-color: #f1f1f1;
  top: calc(100% + 20px);
  box-shadow: 0 4px 4px 1px rgba(0, 0, 0, .1);
width: 1000px;
  padding: 2em 1em;
  height: 700px;
  overflow: hidden;
  overflow-y: scroll;
}
.navbar-expand-lg .navbar-nav .universityDropdown .dropdown-menu {
  width: 700px;
}

.navbar-expand-lg .navbar-nav .universityDropdown .dropdown-item {
  width: calc(100% - 2em);
}
}


@media only screen and (min-width:300px) and (max-width:991px) {

  .navbar-nav .footer-social-icon {
    margin: 2em 0;
  }
  .styles-module_whatsappButton__tVits svg {
    padding: 0.3rem;
    width: 40px;
    height: 40px;
}

.styles-module_whatsappButton__tVits {
  width: 45px;
  height: 45px;
  bottom: 1rem;
  right: 0.7rem;
}

  .footer-widget ul li::after {
    content: " | ";
    color: var(--bs-dark-newgreen);
  }
  .footer-widget ul li:last-child::after {
    content: "";
  }
  .contact-container {
    margin-top: 4em;
}
.contact-container .contact {
  top: -1rem;
}

.contact-container .carousel-caption {
  padding: 2em 1em 1em;
}
.contact-container .carousel-caption,
.contact-container .carousel-item img.d-block.w-100 {
  height: 100vw;
}
  .footer-content {
    padding: 1.5em 0 !important;
  }
  .footer-logo img {
    max-width: 160px
}
  .footer-widget ul li {
    width: auto;
    padding: 0 6px 0 0px;
}
  .innerbanner img {
    margin-top: 65px;
  }
  ._h1tag {
    top: 6.5em;
    left: 0%;
    width: 100%;
    padding: 0 1em;
  }

  .navbar-expand-lg .navbar-nav .dropdown-item {
    margin: 1em 0;
    height: auto;
  }
  .navbar-nav .dropdown-menu {
    border-width: 0;
    padding: 0 12px;
    height: 400px;
    overflow: auto;
}
  .navbar-expand-lg .navbar-nav .dropdown-item h2 {
    font-size: 16px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-item p {
    font-size: 12px;
    margin: 0;
}
 .navbar-brand .img-fluid {
    height: auto;
    width: 180px;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 2rem;
  height: 2rem;
  background-size: 20px 20px;
}
.bannercontainer {
  padding: 0;
  margin-top: 70px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 98%);
  margin-bottom: -2.5em;
}

.banner .carousel-caption h1, .banner .carousel-caption h5 {
  color: #fff
}
.carousel-caption {
  position: absolute;
  right: 0;
  bottom: inherit;
  top: 0;
  height: 400px;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  left: 0;
  align-items: flex-start;
  padding: 0em 1em 2.5em;
  text-align: left;
  background: #00000078;
}

.carousel-item img.d-block.w-100 {
  /* clip-path: polygon(0 0, 100% 0, 100% 100%, 0 88%);
  filter: brightness(0.5); */
  height: 400px;
  object-fit: cover;
}

h5.H5,
.banner .carousel-caption h1, 
.banner .carousel-caption h5 {
  font-size: 1.6em;
  font-weight: bold;
  /* padding: 0; */
}

 .banner .carousel-caption h6 {
  margin: 1em 0;
  font-size: 1.2em;
  line-height: 24px;
  color: #f8f9fa;
  font-style: italic;
}

.heading h6 {
  font-size: 1.4rem;
}

.button-50 {
  line-height: 14px;
  padding: 10px 12px;
  font-size: 14px;
  margin-bottom: 2rem;
}

.aboutus {
  background-color: var(--bs-light-newgreen);
}

.aboutus,

.about,
.about#ourteam {
  padding: 3em 0em;
  clip-path: polygon(0 0, 100% 2%, 100% 100%, 0 98%);
}

.about#aboutus,
.contact {
  padding: 3em 0em 0;
}
.servies {
  padding: 3em 0em 5em;
  clip-path: polygon(0 0, 100% 1%, 100% 100%, 0 99%);
}

.heading .H5::after {
  content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 7px;
    width: 60px;;
    background: var(--bs-dark-newblue);
}
.contactcontainer {
  padding: 2rem 1em 6em;
  margin-bottom: -1rem;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
}
.heading .serviesh6 {
  font-size: 14px;
  margin: 0 0 2.5em;
}

.about#ourteam .row.col,
.serivce-row {
margin: 0 auto;
}


.about#ourteam .row.col {
  margin: 0 auto;
  padding: 0 0 2em;
  /* margin-bottom: -7px; */
}
.serivce-row .mb-4 {
  margin-bottom: 2.5rem!important;
}

.user-card-designer {
  margin: 0 0 2rem;
  width: 100%;
}
.footer-social-icon,
.col-xl-4.col-md-4.mb-30 {
  margin-bottom: 1em;
}
.footer-cta.pt-5.pb-5 {
  padding: 2em 0 0em !important;
}

.footer-social-icon span,
.footer-widget-heading h3,
.contact .nav-link {
  font-size: 16px;
  margin-bottom: 24px;
}
.contact .nav-link svg {
  width: 30px;
  height: 30px;
  padding: 5pt;
}

.user-card-designer {
  margin: 0 1px 0rem;
  width: calc(100% / 2 - 2px);
}

.article_6 {
  padding-top: 1.8em;
}
.article_6 .col-12.col-lg-7.col  {
  padding-bottom: 1em;
}
.user-card-intro {
  padding: 1em 6px;
}
.card-body a.link-primary {
  font-size: 12px;
}

.cta-text h4 {
  font-size: 16px;
}
.footer-text p {
  line-height: 20px;
}

.footer-widget ul li a,
.footer-text p,
.cta-text span,
.article_6 p,
.card-body p.text-secondary,
.user-card-intro h3 {
  font-size: 13px;
}
.user-card-intro p {
  font-size: x-small;
}
.card-body h4 {
  font-size: 1.6em;
  height: 120px;
}
.card-body img {
  height: 145px;
}
.card-body {
  height: auto;
}
.card-info {
  padding: .6em 1rem 2.8em;
}
.cta-text {
  padding-left: 0px;
}

.footer-widget-heading h3::before {
  bottom: -10px;
  height: 4px;
  width: 35px;
}
}

@media only screen and (min-width:989px) and (max-width:1360px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}


}

@media only screen and (min-width:989px) and (max-width:1490px) {
  .carousel-item .carousel-caption {
    padding: 0em 2em 2.5em;
  }
  

  .bg-body-tertiary.navbar {
    height: 70px;
}

.carousel-caption {
  padding: 0em 8em 2.5em;
}
.navbar-nav .footer-social-icon svg.bi {
  height: 30px;
  margin-right: 0px;
  padding: 4pt;
  width: 30px;
}

.navbar-expand-lg .navbar-collapse {
  font-size: 13px;
}
.navbar-brand .img-fluid {
  height: auto;
  width: 140px;
  padding: 0 10px;
}
.navbar-expand-lg .navbar-nav svg.bi.bi-envelope-at-fill,.navbar-expand-lg .navbar-nav svg.bi.bi-phone-fill {
  width: 16px;
  height: 16px;
}

.navbar-expand-lg .navbar-nav .footer-social-icon a {
  margin-right: 8px;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}
.navbar-expand-lg .navbar-nav .dropdown-item {
  width: calc(100% / 2 - 1em);
  text-align: left;
}

.navbar-nav .dropdown-menu {
  height: 500px;
  width: 800px;
  text-align: center;
}
.navbar-expand-lg .navbar-nav .dropdown-item {
  margin: 1em 0.5em;
}

.dropdown-menu[data-bs-popper] {
  left: -10em;
}

.navbar-nav .footer-social-icon svg.bi, .contact svg.bi, .contact .nav-link svg {
  width: 35px;
  height: 35px;
  padding: 5pt;
}

.contact .footer-social-icon {
  margin-top: 0em;
}
.user-card-designer {
  margin: 0 0.5em 1rem;
  width: calc(100% / 2 - 1em);
}
.alternative-cta-overline-wrapper {
  grid-column-gap: 0.3rem;
}

.alternative-cta-pellet {
  width: 10px;
  height: 10px;
  top: 5px;
}
.banner .carousel-caption h6 {
  font-size: 24px;
}
.aboutus p,
.heading .serviesh6,
.footer-widget ul li a, .cta-text span,
.article_6 p,
.footer-text p,
.card-body p.text-secondary,
.user-card-intro p {
  font-size: 14px;
}
.heading h6,
.contact .nav-link,
   .user-card-intro h3 {
  font-size: 16px;
}
h5.H5, .banner .carousel-caption h1, .banner .carousel-caption h5 {
  font-size: 20px;
}

.heading .H5::after {
  content: "";
  bottom: -12px;
  height: 5px;
  width: 50px;
}
}